import {ProjectBot} from "@/types/projects.types";
import { BaseUser } from "@/types/users.types";

export const baseUser: BaseUser = {
  mudId: '',
  email: '',
};

export const baseProjectBot: ProjectBot = {
  path: '',
  botName: '',
  fileName: '',
  botRegistrationId: -1,
  botDeploymentId: -1,
  listingId: -1,
  riskPartner: baseUser,
  businessUnit: '',
  deliveredFor: '',
  environments: [],
  businessOwner: baseUser,
  botSystemMnemonic: 'V11',
  botDescription: '',
  businessProcess: '',
  configurationId: '',
  configurationIdName: '',
  systemIntegrations: [],
  promotionStatuses: [],
  snowData: { snowDetails: {} },
  isOnCyberArk: false,
  systems: []
};

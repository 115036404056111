
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { omit } from 'lodash';
import {Environments, listingTypesById, RouteNames} from '@/constants';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { UINavigationItem } from '@/types';
import NavigationTabs from '@/components/NavigationTabs.vue';
import EmptyState from '@/components/EmptyState.vue';
import {
  BotDeployment,
  ChecklistBotDetails,
  ProjectBot,
  PromotionChecklistData,
  PromotionStatus,
} from '@/types/projects.types';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import ViewChecklist from '@/components/projects/ViewChecklist.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import { Environment, IEnvironmentMnemonic } from '@/types/enum.types';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';
import { baseProjectBot } from '@/constants/initial.values';
import * as API from '@/api/projects.api';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { EnumsModule } from '@/store/modules/enums.module';
import { Fragment } from 'vue-fragment';
import { BotSystemMnemonic } from '@/Rpa/constants';

type GskMenu = Menu;

const BotActions = {
  Edit: 'edit',
  Delete: 'delete',
  Remove: 'remove-deployment',
  RegisterV11: 'reg-v11',
  RegisterA360: 'reg-a360',
};

@Component({
  components: {
    IconMenu,
    NavigationTabs,
    EmptyState,
    GDialog,
    ViewChecklist,
    Fragment,
  },
})
export default class ProjectRpaDetails extends Vue {
  $refs!: {
    menu: GskMenu;
    menuAnchor: HTMLElement;
  };

  public envId = '';
  public projectId = '';

  public projectBotsLoading = false;

  protected openConfirmDeleteDialog = false;

  public openProductionChecklist = false;
  protected checklistData: PromotionChecklistData = {
    jiraTicketNumber: '',
    techChampion: [],
    deploymentType: '',
    botChanges: [],
    functionalDesign: {
      ticketNumber: '',
      completed: '',
    },
    technicalDesign: {
      ticketNumber: '',
      completed: '',
    },
    codeReview: {
      ticketNumber: '',
      completed: '',
    },
    unitTesting: {
      ticketNumber: '',
      completed: '',
    },
    riskAssessment: {
      ticketNumber: '',
      completed: '',
    },
    rampUpPlan: {
      ticketNumber: '',
      completed: '',
    },
    targetApplications: {
      ticketNumber: '',
      completed: '',
    },
    robotAccounts: {
      ticketNumber: '',
      completed: '',
    },
    virtualDesktop: {
      ticketNumber: '',
      completed: '',
    },
    businessUnitTeam: {
      ticketNumber: '',
      completed: '',
    },
  };
  protected checklistBotDetails: ChecklistBotDetails = {
    botName: '',
    botDescription: '',
    businessUnit: '',
    botSystem: '',
  };

  protected botSelectedForRemoval: ProjectBot = baseProjectBot;
  protected botDeploymentSelectedForRemoval?: BotDeployment;

  public collapsedTableRows: any = {};

  get getBotActionOptions() {
    return (bot: ProjectBot): MenuOption[] => {
      const originalBotRegistration = this.projectBotList.find(
        (botRegistration) => botRegistration.botRegistrationId === bot.botRegistrationId,
      );
      const { systems: devSystems } = originalBotRegistration as ProjectBot;
      if (this.tab === Environment.RPA_DEV) {
        const menuItems = [
          {
            key: BotActions.Edit,
            text: 'Edit Bot',
          }
        ];
        if (FeatureFlagsModule.a360Enabled) {
          menuItems.push({
            key: BotActions.RegisterA360,
            text: 'Register A360 bot',
            disabled: !!devSystems.find((s) => s.systemMnemonic === BotSystemMnemonic.A360),
          });
        }
        menuItems.push({
          key: BotActions.RegisterV11,
          text: 'Register V11 bot',
          disabled: !!devSystems.find((s) => s.systemMnemonic === BotSystemMnemonic.V11),
        });
        return menuItems;
      }
      else
      {
        return [
        {
          key: BotActions.Edit,
          text: 'Edit Bot',
        },];
      }
    };
  }

  get botDeploymentActionOptions(): MenuOption[] {
    return [
      {
        key: BotActions.Remove,
        text: 'Remove Bot',
        style: {
          color: 'red',
        },
      },
    ];
  }

  doBotAction(bot: ProjectBot, event: MenuOption, botDeployment?: BotDeployment): void {
    if (event.key === BotActions.Remove) {
      this.botSelectedForRemoval = bot;
      this.botDeploymentSelectedForRemoval = botDeployment;
      this.openConfirmDeleteDialog = true;
    } else if (event.key === BotActions.Delete) {
      this.botSelectedForRemoval = bot;
      this.openConfirmDeleteDialog = true;
    } else if (event.key === BotActions.Edit) {
      this.$router.push(this.editBot(bot));
    } else if (event.key === BotActions.RegisterA360) {
      this.$router.push(this.addBot(bot.botRegistrationId, BotSystemMnemonic.A360));
    } else if (event.key === BotActions.RegisterV11) {
      this.$router.push(this.addBot(bot.botRegistrationId, BotSystemMnemonic.V11));
    }
  }

  closeDialog(): void {
    this.botSelectedForRemoval = baseProjectBot;
    this.botDeploymentSelectedForRemoval = undefined;
    this.openConfirmDeleteDialog = false;
  }

  get collapsedBotRow(): (key: number) => boolean {
    return (key: number): boolean => String(key) in this.collapsedTableRows;
  }

  expandCollapseRow(botRegistrationId: number) {
    const key = String(botRegistrationId);
    if (key in this.collapsedTableRows) {
      this.collapsedTableRows = omit(this.collapsedTableRows, [key]);
    } else {
      this.collapsedTableRows = {
        ...this.collapsedTableRows,
        [key]: true,
      };
    }
  }

  async confirmRemoveBot(): Promise<void> {
    try {
      await API.removeBotDeploymentFromProject(
        Number(this.projectId),
        Number(this.botDeploymentSelectedForRemoval!.botDeploymentId),
      );
      openSnackbar.call(this, 'Successfully Removed Bot Deployment');
      this.projectBotsLoading = true;
      this.$nextTick(() => {
        ProjectDetailsModule.getProject(Number(this.projectId))
          .finally(() => {
            this.projectBotsLoading = false;
          });
      });
    } catch (ex: any) {
      openErrorSnackbar.call(this, ex.response?.data?.message ?? 'Server Error');
    } finally {
      this.closeDialog();
    }
  }

  botIdforTooltip(id: number): string {
    return 'toolTip'.concat((id ?? '').toString());
  }

  get tab(): IEnvironmentMnemonic {
    return this.$route.params.env as IEnvironmentMnemonic;
  }

  public get tabs(): UINavigationItem[] {
    const tabs: UINavigationItem[] = ProjectDetailsModule.rpaEnvs.map(env => {
      return {
        text: env.displayName,
        key: env.mnemonic,
        route: {
          name: RouteNames.ProjectRpa,
          params: {
            ...this.$route.params,
            env: env.mnemonic,
          },
        },
      };
    });

    const dummyQa = {
      text: 'QA',
      key: Environment.RPA_QA,
      route: {},
      disabled: true,
    };

    const dummyProd = {
      text: 'Prod',
      key: Environment.RPA_PROD,
      route: {},
      disabled: true,
    };

    if (tabs.length === 2) {
      tabs.push(dummyProd);
    } else if (tabs.length === 1) {
      tabs.push(dummyQa);
      tabs.push(dummyProd);
    }

    return tabs;
  }

  created(): void {
    const { id, env } = this.$route.params;
    this.envId = env;
    this.projectId = id;
    // Reset Flow state in case user was navigated here
    ProjectDetailsRpaModule.resetBotDetailsState();
    // Reset Qa Promotion State
    ProjectDetailsRpaModule.resetProdChecklist();
    // Reset Select Bot status
    ProjectDetailsRpaModule.resetSelectBotStatus();
  }

  promoteBot(bot: ProjectBot, botDeployment: BotDeployment): void {
    // Set promoting bot in state
    ProjectDetailsRpaModule.setPromotingBot(bot);
    // route to promotion
    this.$router.push(this.promoteBotRoute(bot, botDeployment.botDeploymentId));
  }

  get canEditProject(): boolean {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    return EnumsModule.enums.role.OWNER.id === currentUserRoleId;
  }

  get projectBotList(): ProjectBot[] {
    const { bots } = ProjectDetailsModule.projectDetails;
    return bots ? bots : [];
  }

  get atleastOneBotExists(): boolean {
    const envBots = [this.devBots, this.qaBots, this.prodBots];
    return (
      envBots.reduce((acc: ProjectBot[], bots) => {
        if (bots.length) {
          acc.push(bots[0]);
        }
        return acc;
      }, []).length > 0
    );
  }

  goToListing(bot: ProjectBot): Location {
    if (bot.listingDetails) {
      const { listingName, listingTypeId } = bot.listingDetails;
      const listingType = listingTypesById[listingTypeId];
      return {
        name: RouteNames.ListingDetails,
        params: {
          type: listingType,
          listingId: bot.listingId ? bot.listingId.toString() : '-1',
          name: listingName,
        },
      };
    }
    return {};
  }

  get projectBots(): ProjectBot[] {
    if (this.tab === Environment.RPA_DEV) {
      return this.devBots;
    } else if (this.tab === Environment.RPA_QA) {
      return this.qaBots;
    } else if (this.tab === Environment.RPA_PROD) {
      return this.prodBots;
    } else {
      return [];
    }
  }

  get devBots(): ProjectBot[] {
    let bots: ProjectBot[] = [];
    this.projectBotList.forEach(bot => {
      const botRegistration: ProjectBot = { ...bot, systems: [] };
      const environmentSystems: string[] = [];
      bot.environments.forEach(env => {
        if (env.displayName === Environments.Dev) {
          const [systemMnemonic] = env.systems;
          const { A360 } = EnumsModule.enums.botSystems;
          if (!FeatureFlagsModule.a360Enabled && systemMnemonic === A360.mnemonic) {
            return;
          }
          environmentSystems.push(systemMnemonic);
        }
      });
      bot.systems.forEach((botDeployment) => {
        if (environmentSystems.includes(botDeployment.systemMnemonic)) {
          botRegistration.systems.push(botDeployment);
        }
      });
      if (environmentSystems.length > 0) {
        bots.push(botRegistration);
      }
    });
    return bots;
  }

  get qaBots(): ProjectBot[] {
    let bots: ProjectBot[] = [];
    this.projectBotList.forEach(bot => {
      const botRegistration: ProjectBot = { ...bot, systems: [] };
      const environmentSystems: string[] = [];
      bot.environments.forEach(env => {
        if (env.displayName === Environments.Qa) {
          const [systemMnemonic] = env.systems;
          const { A360 } = EnumsModule.enums.botSystems;
          if (!FeatureFlagsModule.a360Enabled && systemMnemonic === A360.mnemonic) {
            return;
          }
          environmentSystems.push(systemMnemonic);
        }
      });
      bot.systems.forEach((botDeployment) => {
        if (environmentSystems.includes(botDeployment.systemMnemonic)) {
          botRegistration.systems.push(botDeployment);
        }
      });
      if (environmentSystems.length > 0) {
        bots.push(botRegistration);
      }
    });
    return bots;
  }

  get prodBots(): ProjectBot[] {
    let bots: ProjectBot[] = [];
    this.projectBotList.forEach(bot => {
      const botRegistration: ProjectBot = { ...bot, systems: [] };
      const environmentSystems: {
        botPromotionPendingApproval: boolean | undefined,
        systemMnemonic: string,
      }[] = [];
      bot.environments.forEach(env => {
        if (env.displayName === Environments.Prod) {
          const [systemMnemonic] = env.systems;
          const { A360 } = EnumsModule.enums.botSystems;
          if (!FeatureFlagsModule.a360Enabled && systemMnemonic === A360.mnemonic) {
            return;
          }
          environmentSystems.push({
            systemMnemonic,
            botPromotionPendingApproval: env.botPromotionPendingApproval,
          });
        }
      });
      bot.systems.forEach((botDeployment) => {
        const envInfo = environmentSystems.find(q => q.systemMnemonic === botDeployment.systemMnemonic);
        if (envInfo) {
          botRegistration.systems.push({
            ...botDeployment,
            botPromotionPendingApproval: envInfo.botPromotionPendingApproval,
          });
        }
      });
      if (environmentSystems.length > 0) {
        bots.push(botRegistration);
      }
    });
    return bots;
  }

  get promotingToEnv(): string {
    return this.isQAEnv ? Environments.Prod : this.isDevEnv ? Environments.Qa : '';
  }

  get isQAEnv(): boolean {
    return this.tab === Environment.RPA_QA;
  }

  get isDevEnv(): boolean {
    return this.tab === Environment.RPA_DEV;
  }

  get isProdEnv(): boolean {
    return this.tab === Environment.RPA_PROD;
  }

  get notProd(): boolean {
    return this.tab !== Environment.RPA_PROD;
  }

  get prodPromotionEnabled(): boolean {
    return FeatureFlagsModule.botProdPromotionEnabled;
  }

  get hasNoQaEnvironment(): boolean {
    return !this.qaBots.length;
  }

  get hasNoProdEnvironment(): boolean {
    return this.prodPromotionEnabled ? !this.prodBots.length : true;
  }

  showProductionChecklist(bot: ProjectBot, botDeployment: BotDeployment): void {
    this.openProductionChecklist = true;
    const { checklistData } = this.approvalDetails(bot, botDeployment);
    this.checklistData = checklistData;
    const { botName, botDescription, businessUnit } = bot;
    const { systemMnemonic } = botDeployment;
    this.checklistBotDetails = {
      botName,
      botDescription,
      businessUnit,
      botSystem: systemMnemonic,
    };
  }

  promotionDate(bot: ProjectBot, botDeployment: BotDeployment): string {
    const { botPromotionCreateTimestamp } = this.approvalDetails(bot, botDeployment);
    const date = new Date(botPromotionCreateTimestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  promotionRequestor(bot: ProjectBot, botDeployment: BotDeployment): string {
    const { botPromotionCreateUser } = this.approvalDetails(bot, botDeployment);
    const { fullName } = botPromotionCreateUser;
    return fullName || 'RPA User';
  }

  botStatusDenied(bot: ProjectBot): boolean {
    const { promotionStatuses } = bot;
    let botPromotionStatuses = promotionStatuses.filter(
      status => status.botDeploymentId === bot.botDeploymentId,
    );
    return last(botPromotionStatuses)?.statusName === 'Request Denied';
  }

  prodPromotionStatus(bot: ProjectBot, botDeployment: BotDeployment): string {
    const { promotionStatuses } = bot;
    const { botDeploymentId } = botDeployment;
    let prodPromotionStatuses = promotionStatuses.filter(
      status => status.fromEnvMnemonic === 'RPA_QA' && status.botDeploymentId === botDeploymentId,
    );
    return last(sortBy(prodPromotionStatuses, 'botPromotionCreateTimestamp'))!.statusName;
  }

  approvalDetails(bot: ProjectBot, botDeployment: BotDeployment): PromotionStatus {
    let projectEnvId = -1;
    let promotionStatusData: PromotionStatus = {
      botRegistrationId: -1,
      botPromotionCreateTimestamp: '',
      botPromotionCreateUser: {
        mudId: '',
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
      },
      checklistData: {
        jiraTicketNumber: '',
        techChampion: [],
        deploymentType: '',
        botChanges: [],
        functionalDesign: {
          ticketNumber: '',
          completed: '',
        },
        technicalDesign: {
          ticketNumber: '',
          completed: '',
        },
        codeReview: {
          ticketNumber: '',
          completed: '',
        },
        unitTesting: {
          ticketNumber: '',
          completed: '',
        },
        riskAssessment: {
          ticketNumber: '',
          completed: '',
        },
        rampUpPlan: {
          ticketNumber: '',
          completed: '',
        },
        targetApplications: {
          ticketNumber: '',
          completed: '',
        },
        robotAccounts: {
          ticketNumber: '',
          completed: '',
        },
        virtualDesktop: {
          ticketNumber: '',
          completed: '',
        },
        businessUnitTeam: {
          ticketNumber: '',
          completed: '',
        },
      },
      fromEnvDisplayName: '',
      fromEnvMnemonic: '',
      fromEnvName: '',
      fromProjectEnvId: -1,
      mnemonic: '',
      packageId: '',
      statusDescription: '',
      statusId: -1,
      statusName: '',
      statusTypeId: -1,
      toEnvDisplayName: '',
      toEnvMnemonic: '',
      toEnvName: '',
      toProjectEnvId: -1,
    };

    const { environments, promotionStatuses } = bot;

    environments.forEach(e => {
      if (e.botPromotionPendingApproval && e.systems.includes(botDeployment.systemMnemonic)) {
        projectEnvId = e.projectEnvironmentId;
      }
    });

    const getTime = (s: string) => new Date(s).getTime();
    const [recentStatus] = promotionStatuses
      .filter(status => status.toProjectEnvId === projectEnvId && status.botDeploymentId === botDeployment.botDeploymentId)
      .sort((a, b) => getTime(b.botPromotionCreateTimestamp) > getTime(a.botPromotionCreateTimestamp) ? 1 : -1);

    if (recentStatus) {
      promotionStatusData = recentStatus;
    }

    return promotionStatusData;
  }

  addBot(botRegistrationId?: number, botSystemMnemonic?: BotSystemMnemonic): Location {
    return {
      name: RouteNames.NewRpaProjectBot,
      params: {
        type: 'bot',
        botRegistrationId: Number(botRegistrationId) > 0 ? String(botRegistrationId) : 'new',
        botSystem: botSystemMnemonic || BotSystemMnemonic.V11,
        projectId: this.projectId,
        envId: this.$route.params.env,
      },
    };
  }

  promoteBotRoute(bot: ProjectBot, botDeploymentId: number): Location {
    return {
      name: RouteNames.PromoteRpaBot,
      params: {
        projectId: this.projectId,
        envId: this.envId,
        toEnv: this.promotingToEnv,
        botId: bot.botRegistrationId.toString(),
        botDeploymentId: String(botDeploymentId),
      },
    };
  }

  editBot(bot: ProjectBot): Location {
    return {
      name: RouteNames.EditRpaBot,
      params: {
        botRegistrationId: String(bot.botRegistrationId),
        projectId: this.projectId,
        envId: this.envId,
      },
    };
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import GskCheckboxGroupTwo from '@/Rpa/components/gsk-components/GskCheckboxGroupTwo.vue';
import GskRadioGroupTwo from '@/Rpa/components/gsk-components/GskRadioGroupTwo.vue';
import { ChecklistBotDetails, JiraTicketInfo, PromotionChecklistData } from '@/types/projects.types';
import UserCircle from '@/components/UserCircle.vue';
interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

interface ChecklistDetails {
  title: string;
  ticketNumber: string;
  completed: string;
}

@Component({
  components: {
    UserCircle,
    GskRadioGroupTwo,
    GskCheckboxGroupTwo,
    FullScreenForm,
    GButton,
    GPeoplePicker,
    GTextfield,
    GSelect,
  },
})
export default class ViewChecklist extends Vue {
  @Prop({ required: true, type: Object }) readonly checklist!: PromotionChecklistData;
  @Prop({ required: true, type: String }) readonly botDetails!: ChecklistBotDetails;

  private modalClosed = false;

  protected changeControlDetails: ChecklistDetails[] = [
    {
      title:
        'Functional Design Document has been approved by Process Owner and Risk & Compliance functions.',
      ticketNumber: this.functionDesign.ticketNumber,
      completed: this.functionDesign.completed,
    },
    {
      title: 'Technical Design Document has been approved.',
      ticketNumber: this.technicalDesign.ticketNumber,
      completed: this.technicalDesign.completed,
    },
    {
      title:
        'Code review has been approved by independent code reviewer. Any open code quality defect has been declared and risk accepted.',
      ticketNumber: this.codeReview.ticketNumber,
      completed: this.codeReview.completed,
    },
    {
      title:
        'Unit testing has been completed by the development team. UAT has been approved by Process Owner and Risk & Compliance functions. Any open defect or item have been declared and risk accepted.',
      ticketNumber: this.unitTesting.ticketNumber,
      completed: this.unitTesting.completed,
    },
    {
      title:
        'Risk assessment is completed by Risk & Compliance functions. Applies for both new bots and break fixes.',
      ticketNumber: this.riskAssessment.ticketNumber,
      completed: this.riskAssessment.completed,
    },
    {
      title:
        'Ramp-up plan has been completed and agreed with the business. Only applies for new bots.',
      ticketNumber: this.rampUpPlan.ticketNumber,
      completed: this.rampUpPlan.completed,
    },
  ];

  get functionDesign(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.functionalDesign;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get technicalDesign(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.technicalDesign;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get codeReview(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.codeReview;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get unitTesting(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.unitTesting;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get riskAssessment(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.riskAssessment;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get rampUpPlan(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.rampUpPlan;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  protected botExecutionDetails: ChecklistDetails[] = [
    {
      title: 'Access to target applications(s) have been granted to the Robot account(s).',
      ticketNumber: this.targetApplications.ticketNumber,
      completed: this.targetApplications.completed,
    },
    {
      title:
        'Robot account(s) have been created and setup successfully:\n' +
        '\n\t• Robot accounts created through Remedy ticket\n' +
        '\t• Robot accounts have been assigned with the relevant RPA BU' +
        '\t• Tech Champion as account owner\n' +
        '\t• Robot accounts have been registered in PAMS\n' +
        '\t• Robot accounts have been exempted frorm mandatory trainings\n' +
        '\t• Runtime License assigned to Primary Robot account in Production',
      ticketNumber: this.robotAccounts.ticketNumber,
      completed: this.robotAccounts.completed,
    },
    {
      title: 'Virtual desktop has been setup and ready for bot execution in Production.',
      ticketNumber: this.virtualDesktop.ticketNumber,
      completed: this.virtualDesktop.completed,
    },
    {
      title:
        'Business Unit RPA team communicated Secondary Robot account’s credentials to RPA Platform team',
      ticketNumber: this.businessUnitTeam.ticketNumber,
      completed: this.businessUnitTeam.completed,
    },
  ];

  get targetApplications(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.targetApplications;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get robotAccounts(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.robotAccounts;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get virtualDesktop(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.virtualDesktop;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }

  get businessUnitTeam(): JiraTicketInfo {
    let { ticketNumber, completed } = this.checklist.businessUnitTeam;
    ticketNumber = ticketNumber.length > 0 ? ticketNumber : 'N/A';
    return { ticketNumber, completed };
  }
}
